import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor() { }

  navigation() {
    return [
      {
        name: 'Directory',
        link: '/directory',
        icon: 'icon-nav-directory',
        children: [
          {
            name: 'Clients',
            link: 'clients',
          },
          {
            name: 'Suppliers',
            link: 'suppliers',
          },
          {
            name: 'Managers',
            link: 'managers',
          },
          {
            name: 'Services',
            link: 'services',
          },
          // {
          //   name: 'Offices',
          //   link: 'offices',
          // }
          {
            name: 'Banks',
            link: 'banks'
          }
        ]
      },
      {
        name: 'Accounting',
        link: '/accounting',
        icon: 'icon-nav-accounting',
        children: [
          // {
          //   name: 'Client invoices',
          //   link: 'client-invoices',
          // },
          // {
          //   name: 'Supplier invoices',
          //   link: 'supplier-invoices',
          // },
          {
            name: 'Matched data',
            link: 'matched-data',
          },

          {
            name: 'Transactions',
            link: 'transactions',
          },
          {
            name: 'Exchange rates',
            link: 'exchange-rates',
          },
          {
            name: 'Bank cards',
            link: 'bank-cards',
          },
          {
            name: 'Accounting data',
            link: 'accounting-data',
          },
        ]
      },
      {
        name: 'Reports',
        link: '/reports',
        icon: 'icon-nav-reports',
        children: [
          {
            name: 'Clients',
            link: 'clients',
          },
          {
            name: 'Suppliers',
            link: 'suppliers',
          },
          {
            name: 'Requests',
            link: 'requests',
          },
          {
            name: 'Managers',
            link: 'managers',
          },
          {
            name: 'Invoices',
            link: 'invoices',
          },
          {
            name: 'Debts',
            link: 'debts',
          },
          {
            name: 'Fin-Report',
            link: 'fin',
          },
          {
            name: "LOG-report",
            link: "log"
          },
          {
            name: "Supplier-profitability",
            link: "supplier-profitability"
          }
        ]
      },
      {
        name: 'Requests',
        link: '/requests',
        icon: 'icon-nav-requests',
        children: []
      },
      {
        name: 'Tasks',
        link: '/tasks',
        icon: 'icon-nav-tasks',
        children: []
      },
      {
        name: 'Mail agent',
        link: '/mail',
        icon: 'icon-nav-mail',
        children: []
      },
      {
        name: 'Documents',
        link: '/documents',
        icon: 'icon-nav-documents',
        children: []
      }
      /* {
        name: 'Pipeline',
        link: '/pipeline',
        icon: 'icon-nav-pipeline',
        children: []
      },*/
    ];
  }
}
