import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';

import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, finalize, map, retry, share, timeout} from 'rxjs/operators';

import {toFormData} from '@shared/helpers/functions';
import {API_BASE} from '@shared/helpers/constants';
import LogRocket from "logrocket";
import {Permission} from "@shared/models/permissions";

@Injectable({
  providedIn: 'root'
})
export class TokenService implements CanActivate {

  get currentUserData(): any {
    return this.userData.value;
  }

  get isSignedIn(): boolean {
    return !!localStorage.getItem('accessToken');
  }

  get userRole(): string {
    if (this.userData.value?.type === 'admin') {
      return 'Admin';
    } else {
      return this.userData.value?.role;
    }
  }

  get userPermissions(): Permission[] {
    return this.userData.value?.permissions;
  }

  get canDeleteFolders(): boolean {
    return this.userData.value?.permissions?.includes('ROLE_ALLOW_DELETE_FOLDER');
  }

  get hasTemporaryPassword(): boolean {
    return this.userData.value?.temporary_password;
  }

  userData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private _http: HttpClient,
              private _router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.isSignedIn) {
      this._router.navigate(['/login']);
      return false;
    }
    return this.validateToken();
  }

  logIn(data: any) {
    const observ = this._http.post('/auth/token/login/', data)
      .pipe(share());

    observ.subscribe((res: any) => this.userData.next(res.data));

    return observ;
  }

  logOut() {
    return this._http.post('/auth/token/logout/', {})
      .pipe(
        finalize(() => {
            localStorage.removeItem('accessToken');
            this.userData.next(null);
            this._router.navigate(['/login']).then(() => {
              window.location.reload();
            });
          }
        )
      ).subscribe();
  }

  validateToken(): Observable<boolean> {
    return this._http.get<any>(`${API_BASE}/users/me/`)
      .pipe(timeout(2000))
      .pipe(
        retry(5),
        catchError(this.handleError)
      )
      .pipe(
        map(response => {
          this.userData.next(response.data);
          LogRocket.identify(response.data.name, {
            name: response.data.name
          });
          return true;
        }),
        catchError(error => {
          this.logOut();
          return of(false);
        })
      );
  }

  private handleError(error: any) {

    return throwError(error);
  }

}
