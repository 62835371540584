<div class="nav-header d-flex justify-content-between align-items-center" *ngIf="isOpened; else isHiddenHeaderTpl">
  <img class="logotype" src="/assets/images/logotype.png">
  <button class="btn btn-link p-0" (click)="collapseAll()">
    <i class="icon icon-nav-close"></i>
  </button>
</div>
<ng-template #isHiddenHeaderTpl>
  <div class="nav-header d-flex justify-content-center bg-danger">
    <button class="btn btn-link p-0" (click)="isOpened = true">
      <i class="icon icon-nav-burger"></i>
    </button>
  </div>
</ng-template>
<div class="flex-grow-1  border-lighter border-right">
  <div class="list-group">

    <div class="d-flex align-items-center px-3" style="height: 60px; margin-top: 1rem;" *ngIf="isOpened; else isHiddenAvatarTpl">
      <div class="d-flex align-items-center" routerLink="/profile">
        <div class="thumb-list rounded-circle mr-3"
          [style.backgroundImage]="'url(' + (userData?.avatar ? userData.avatar : '/assets/images/avatar.png') + ')'"></div>
        <div class="font-21">{{userData?.name}}</div>
      </div>
      <button class="btn btn-link p-0 ml-auto" (click)="logOut()">
        <i class="icon icon-nav-exit"></i>
      </button>
    </div>

    <ng-template #isHiddenAvatarTpl>
      <a class="list-group-item list-group-item-action d-flex align-items-center"
         placement="right"  [tooltip]="!isOpened ? 'Profile' : null" routerLinkActive="active" routerLink="/profile">
        <i class="icon icon-nav-user"  ></i>
      </a>
    </ng-template>

    <ng-container *ngFor="let item of items">
      <div routerLinkActive="active" *ngIf="item.children.length; else linkTemplate">
        <button [tooltip]="!isOpened ? item.name : null" placement="right" class="list-group-item list-group-item-action d-flex align-items-center" type="button"
          (click)="collapse.toggle(); isOpened = true">
          <i class="icon mr-3" [ngClass]="item.icon" ></i><span>{{item.name}}</span><i class="icon icon-cont-caret ml-auto"></i>
        </button>

        <div class="list-group-children" #collapse="bs-collapse" [collapse]="true">
          <a class="list-group-child-item text-decoration-none" *ngFor="let child of item.children"
             [routerLink]="[item.link, child.link]" routerLinkActive="active">{{child.name}}</a>
        </div>
      </div>
      <ng-template #linkTemplate>
        <a [tooltip]="!isOpened ? item.name : null" placement="right" class="list-group-item list-group-item-action d-flex align-items-center"
           routerLinkActive="active" [routerLink]="item.link">
          <i class="icon mr-3" [ngClass]="item.icon" ></i><span>{{item.name}}</span>
        </a>
      </ng-template>
    </ng-container>
  </div>
</div>
