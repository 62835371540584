export const PER_PAGE = 20;
export const API_BASE = '/api/v1';
export const DATE_FORMAT = 'DD/MM/YYYY';

export enum FLIGHT_CLASSES {
  Economy = 'Economy',
  Business = 'Business',
  First = 'First',
}

export enum TASK_PRIORITIES {
  Normal = 'Normal',
  Urgent = 'Urgent',
}

export enum TASK_STATUSES {
  NotStarted = 'Not started',
  InProgress = 'In progress',
  Control = 'Control',
  Completed = 'Completed'
}

export enum CANCELLATION_POLICY {
  Flexible = 'Flexible',
  NonRefundable = 'Non refundable',
  Both = 'Both',
}

export enum OFFER_FORMS {
  Text = 'Text',
  Presentation = 'Presentation',
}

export enum ROOM_MEALS {
  RoomOnly = 'Room only',
  BadAndBreakfast = 'Bed and breakfast',
  HalfBoard = 'Half board & others',
}

export enum ROOM_TYPES {
  Standard = 'Standard',
  Deluxe = 'Deluxe',
  JuniorSuite = 'Junior suite',
  OneBedroomSuiteAndHigher = 'One bedroom suite and higher',
  AllPossible = 'All possible',
}

export enum MEMBERSHIP {
  the_one = 'The One',
  trial = 'Trial Period',
  invitation = 'Invitation',
  unsubscribed = 'Unsubscribed',
  dubai_visa_service = 'Dubai visa service',
}

export enum GENDERS {
  Male = 'Male',
  Female = 'Female',
}

export enum SERVICE_TYPES {
  Hotel = 'Hotel',
  Transportation = 'Transportation',
  AirTickets = 'Air tickets',
  PrivateJet = 'Private jet',
  // Helicopter = 'Helicopter',
  Other = 'Other',
}
export enum SERVICE_TYPE_ACCOUNTING {
  Accounting = 'Accounting',
}

export enum LANGUAGES {
  RU = 'Russian',
  EN = 'English',
  CN = 'Chinese',
}

export enum ROLES {
  FO = 'FO',
  BO = 'BO',
  Accountant = 'Accountant',
  Admin = 'Admin',
}

export enum TRANSACTION_TYPES {
  'RECEIVE' = 'Receive',
  'SPEND' = 'Spend',
  'RECEIVE-OVERPAYMENT' = 'Receive overpayment',
  'RECEIVE-PREPAYMENT' = 'Receive prepayment',
  'RECEIVE-TRANSFER' = 'Receive transfer',
  'SPEND-OVERPAYMENT' = 'Spend overpayment',
  'SPEND-PREPAYMENT' = 'Spend prepayment',
  'SPEND-TRANSFER' = 'Spend transfer',
}

export enum TRANSACTION_WAYS {
  pay = 'Pay',
  transfer = 'Transfer',
  conversion = 'Conversion',
  reduce = 'Reduce',
  top_up = 'Top-Up',
}

export enum BALANCE_OPTIONS {
  'reduce' = 'Reduce balance',
  'top-up' = 'Increase balance',
}

export enum CURRENCIES {
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  // RUB = 'RUB',
}

export enum PAYMENT_STATUSES {
  Unpaid = 'Unpaid',
  Paid = 'Paid',
}

export enum PAYMENT_CLIENT_STATUSES {
  Sent = 'Sent',
  NotSent = 'Not sent',
  Unpaid = 'Unpaid',
  Partial = 'Partial',
  Paid = 'Paid'
}

export enum PAYMENT_VOUCHER_STATUSES {
  Inactive = 'Inactive',
  Active = 'Active',
  Paid = 'Paid',
}

export const LOG_REQUEST_STATUSES = [
  'NEW',
  'SENT FOR CORRECTION',
  'CONFIRM',
];

export const ACCOUNT_STATUSES = [
  'Confirmed',
  'Invoiced',
  'Accounted',
  'Canceled'
];

export enum PAYMENT_METHODS {
  Deposit = 'Deposit',
  // Wise = 'Wise',
  Stripe = 'Stripe',
  Wire = 'Wire',
  // Card = 'Card Payment',
  Cash = 'Cash',
}

export enum RATINGS {
  Basic = 'Basic',
  TestPeriod = 'Test period',
  Strategic = 'Strategic',
  Blacklisted = 'Blacklisted',
}

export const SERVICE_SUBTYPES = [
  'Yacht Charter',
  'Airport VIP Lounge',
  'Guide',
  'Travel (tickets)',
  'Meet & greet Vip ARIVAL',
  'Food & drinks expenses:  stuff and personal',
  'Tickets for events',
  'Visa packages',
  'Translations',
  'Courier services',
  'Direct goods for clients',
  'COVID tests',
  'Medical services & appointment',
  'Services on the name of the client (insurance)',
  'Air tickets amendments',
  'Commission'
];
export const ACCOUNT_TYPES = [
  'Toms EU',
  'Toms non EU',
  'Disbursement'
];
export const REQUEST_COMPANIES = [
  'BSLF',
  'TPRO',
  'AIRS',
];
export const HOURS = [
  '00',
  '01',
  '02',
  '03',
  '04',
  '05',
  '06',
  '07',
  '08',
  '09',
  '10',
  '11',
  '12',
  '13',
  '14',
  '15',
  '16',
  '17',
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
];

export const MINUTES = [
  '00',
  '05',
  '10',
  '15',
  '20',
  '25',
  '30',
  '35',
  '40',
  '45',
  '50',
  '55',
];

export function enumList(name: string) {
  // tslint:disable-next-line:no-eval
  const enumObj = eval(name.toUpperCase());
  return Object.keys(enumObj).map(key => ({value: key, named: enumObj[key]}));
}

export function arrayList(name: string) {
  // tslint:disable-next-line:no-eval
  const arrayObj = eval(name.toUpperCase());
  return arrayObj.map(item => ({value: item, named: item}));
}
