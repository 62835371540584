import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { API_BASE } from '@shared/helpers/constants';
import { toFormData } from '@shared/helpers/functions';

@Injectable({
  providedIn: 'root'
})
export class RateService {

  constructor(private _http: HttpClient) { }

  list(year: number, month: number, params?: any): Observable<any[]> {
    return this._http.get(`${API_BASE}/exchange-rates/${year}/${month}/`, { params })
      .pipe(
        map(response => response['data'])
      );
  }

  fullList(): Observable<any> {
    return this._http.get(`${API_BASE}/exchange-rates/get-latest-rate/` )
      // .pipe(
      //   map(response => {
      //     console.log(response, response['data'], "RATES")
      //     return response['data']})
      // );
  }

  rate(params?: any): Observable<any> {
    return this._http.get(`${API_BASE}/exchange-rates/get-last-rate/`, { params })
      .pipe(
        map(response => response['data'])
      );
  }

  create(data: any): Observable<any> {
    return this._http.post<any>(`${API_BASE}/exchange-rates/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  update(data: any): Observable<any> {
    return this._http.patch<any>(`${API_BASE}/exchange-rates/${data.id}/`, data)
      .pipe(
        map(response => response['data'])
      );
  }

  delete(id: string): Observable<any> {
    return this._http.delete(`${API_BASE}/exchange-rates/${id}/`);
  }

}
