<div class="modal-header d-flex align-items-center justify-content-between">
  <div class="modal-title pull-left font-25 font-weight-normal">
    <h2 class="font-weight-normal mb-0">{{data?.id ? 'Update exchange rate' : 'New exchange rate'}}</h2>
  </div>
  <button type="button" class="btn btn-link p-0" aria-label="Close" (click)="bsModalRef.hide()">
    <i class="icon icon-modal-close bg-secondary"></i>
  </button>
</div>
<div class="modal-body p-4">
  <h3 class="mb-0">Main info</h3>
  <form [formGroup]="formData">
    <div class="row mb-3">
      <div class="col-6">
        <form-typeahead placeholder="From*" formControlName="original_currency" object="currencies" [value]="from"
                        [readonly]="formType == 'update'">
        </form-typeahead>
      </div>
      <div class="col-6">
        <form-typeahead placeholder="To*" formControlName="target_currency" object="currencies" [value]="to"
                        [readonly]="formType == 'update'">
        </form-typeahead>
      </div>
      <div class="col-12">
        <form-input placeholder="Internal Rate*" formControlName="internal_rate" controlMask="0*.0*" [specialCharacters]="['.']"></form-input>
      </div>
<!--      <div class="col-6">-->
<!--        <form-input placeholder="Client rate" formControlName="client_rate"></form-input>-->
<!--      </div>-->
      <form-input formControlName="created_at" type="hidden"></form-input>
    </div>
  </form>
</div>
<div class="modal-footer px-4">
  <button type="button" class="btn btn-sm btn-danger" (click)="onSubmit()" [disabled]="loading">
    {{data?.id ? 'Update' : 'Create'}}
  </button>
</div>
