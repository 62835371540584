import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { utils } from '@shared/helpers/utils';
import { TokenService } from '@shared/services/token.service';
import { RateService } from '@shared/services/rate.service';
import { Observable } from 'rxjs';
import {XeroService} from "@shared/services/xero.service";
import {ToastrService} from "ngx-toastr";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ModalCurrencyConverterComponent} from "@shared/components/modal-currency-converter/modal-currency-converter.component";
import {ModalExchangeRateCreateComponent} from "../../../pages/accounting/exchange-rates/components/modal-exchange-rate-create/modal-exchange-rate-create.component";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isPopupOpen = false;
  queryString: string;
  userData: any;
  userRole: string;
  rateData$: Observable<any>;
  isXeroAuth: boolean;
  bsModalRef: BsModalRef;

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _tokenService: TokenService,
              private _rateService: RateService,
              private _xeroService: XeroService,
              private _toastr: ToastrService,
              private _modalService: BsModalService) { }

  ngOnInit() {
    this.userData = this._tokenService.currentUserData;
    this.userRole = this._tokenService.userRole;
    this.queryString = this._route.snapshot.queryParams['search'];

    const date = new Date();
    // this.rateData = this._rateService.list(date.getFullYear(), date.getMonth() + 1);
    this.rateData$ = this._rateService.fullList();

    this._xeroService.isAuth().subscribe(response => {
      this.isXeroAuth = response;
    });
  }

  search() {
    if (this.queryString) {
      this.queryString = this.queryString.trim();
    }
    const params = {
      queryParams: utils.clearEmpty({ search: this.queryString }),
      fragment: this._route.snapshot.fragment || 'Requests'
    };
    this._router.navigate(['/search'], params);
  }

  notify() {
    this._router.navigate(['/notifications']);
  }

  get initials() {
    return this.userData && this.userData.name.replace(/[a-z]/g, '');
  }
  openSearchPopup(): void {
    this.isPopupOpen = true;
  }

  xeroAuth() {
    this._xeroService.auth()
      .subscribe(response => {
        window.location.href = response;
      });
  }

  xeroSync() {
    this._xeroService.sync()
      .subscribe(response => {
        this._toastr.success('Xero sync success;', null, { closeButton: true });
      });
  }

  openCurrencyConverterModal() {
    const config = {
      ignoreBackdropClick: true,
      class: 'modal-md',
    };
    this.bsModalRef = this._modalService.show(ModalCurrencyConverterComponent, config);
  }

  openCreateRateModal(from, to, previousRate) {
    const config = {
      ignoreBackdropClick: true,
      class: 'modal-md',
      initialState: {
        date: new Date(),
        from,
        to,
        previousRate
      }
    };
    this.bsModalRef = this._modalService.show(ModalExchangeRateCreateComponent, config);
  }
}
