<div class="d-flex align-items-center border-lighter border-bottom header-wrapper justify-content-end position-relative">
  <div class="search-popup d-flex justify-content-between align-items-center" *ngIf="isPopupOpen">
    <input type="text" class="form-control border-0" placeholder="Search"
           (keyup.enter)="search()" [(ngModel)]="queryString">
    <span class="close-popup" (click)="isPopupOpen=false">X</span>
  </div>
  <div class="mr-4">
    <div class="input-group">
      <div class="input-group-prepend mr-1">
        <span *ngIf="!isPopupOpen" class="search-but input-group-text bg-transparent border-0" (click)="openSearchPopup()" [tooltip]="'Search'" placement="bottom"><i class="icon icon-cont-search-sm bg-lighter"></i></span>
      </div>
    </div>
  </div>
  <div class="d-flex align-items-center">

    <div class="btn-group" *ngIf="isXeroAuth !== undefined" dropdown>
      <button type="button"
              dropdownToggle
              class="d-flex justify-content-center align-items-center btn dropdown-toggle rounded-circle text-uppercase mr-3"
              [ngClass]="isXeroAuth ? 'btn-success' : 'btn-secondary text-white'"
              style="font-size: 10px" placement="bottom">xero</button>
      <ul
        *dropdownMenu
        class="dropdown-menu" role="menu">
        <button type="button" class="btn btn-danger mr-3 text-uppercase w-100" (click)="xeroAuth()">Xero auth</button>
        <button type="button" class="btn btn-danger text-uppercase w-100" [disabled]="!isXeroAuth" (click)="xeroSync()">Xero sync</button>
      </ul>
    </div>

    <button type="button" class="d-flex justify-content-center align-items-center btn btn-secondary dropdown-toggle rounded-circle mr-3" [tooltip]="'Exchange rates'" placement="bottom"
            [popover]="popTemplate"
            [outsideClick]="true">
      <i class="icon icon-cont-wallet-sm bg-white"></i>
    </button>

    <ng-template #popTemplate>
      <table class="table table-sm">
        <thead>
          <tr class="text-center">
            <th scope="col">From </th>
            <th scope="col">To</th>
            <th scope="col">Internal rate</th>
            <th scope="col">Created date</th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let item of ( rateData$ | async )">
            <td>
              {{item?.original_currency?.named}}
            </td>
            <td>
              {{item?.target_currency?.named}}
            </td>
            <td>
              <span (click)="openCreateRateModal(item.original_currency, item.target_currency, item.internal_rate)"
                    class="text-hover-link underline">{{item?.internal_rate}}
              </span>
            </td>
            <td>
              {{item?.created_at | date :'dd/MM/yyyy'}}
            </td>
          </tr>
        </tbody>
      </table>
    </ng-template>
    <button type="button" class="d-flex justify-content-center align-items-center btn btn-secondary dropdown-toggle rounded-circle mr-3"
            [tooltip]="'Currency converter'" placement="bottom" (click)="openCurrencyConverterModal()">
      <i class="icon icon-cont-currency-exchange bg-white"></i>
    </button>
    <div class="btn-group" dropdown>
      <button type="button" dropdownToggle class="d-flex justify-content-center align-items-center btn btn-danger dropdown-toggle rounded-circle mr-3" [tooltip]="'Add...'" placement="bottom">
        <i class="icon icon-cont-plus bg-white"></i>
      </button>
      <ul *dropdownMenu class="dropdown-menu" role="menu">
        <li role="menuitem"><a class="dropdown-item" routerLink="/requests/create" target="_blank">New request</a></li>
        <li *ngIf="['Accountant', 'Admin'].includes(userRole)" role="menuitem"><a class="dropdown-item" routerLink="/requests/create-accounting" target="_blank">New accounting request</a></li>
        <li role="menuitem"><a class="dropdown-item" routerLink="/directory/suppliers/new" target="_blank">New supplier</a></li>
        <li role="menuitem"><a class="dropdown-item" routerLink="/directory/clients/new" target="_blank">New client</a>
        </li>
      </ul>
    </div>
    <button type="button" class="btn btn-link position-relative border-0 p-0 mr-3" [class.notice]="userData?.notify_counter" [tooltip]="'Notifications'" placement="bottom"
            (click)="notify()">
      <i class="icon icon-cont-bell bg-secondary"></i>
    </button>
    <a routerLink="/profile" class="thumb-list rounded-circle mr-3" *ngIf="userData?.avatar; else nameTemplate"
       [style.backgroundImage]="'url(' + userData.avatar + ')'"></a>
    <ng-template #nameTemplate>
      <a routerLink="/profile" class="d-flex justify-content-center align-items-center btn btn-secondary rounded-circle" [tooltip]="'Profile'" placement="bottom">
        {{initials}}
      </a>
    </ng-template>
  </div>

</div>

