import { Component, ElementRef, EventEmitter, forwardRef, Injector, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LowerCasePipe, TitleCasePipe, UpperCasePipe } from '@angular/common';
import { ControlContainer, ControlValueAccessor, FormControl, FormControlDirective, NG_VALUE_ACCESSOR } from '@angular/forms';

import { utils } from '@shared/helpers/utils';
import { QueryService } from "@shared/services/query.service";

@Component({
  selector: 'form-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent implements ControlValueAccessor, OnInit {
  // tslint:disable-next-line:variable-name
  @ViewChild(FormControlDirective, { static: true }) FormControlDirective: FormControlDirective;
  @ViewChild('elementRef', { static: false }) elementRef: ElementRef;
  @Output() eventBlur: EventEmitter<any> = new EventEmitter();
  @Output() eventPress: EventEmitter<any> = new EventEmitter();
  @Input() formControl: FormControl;
  @Input() formControlName: string;
  @Input() icon: string;
  @Input() type = 'text';
  @Input() label: string;
  @Input() placeholder: string;
  @Input() helper: string;
  @Input() min: number;
  @Input() max: number;
  @Input() minlength: number;
  @Input() maxlength: number;
  @Input() readonly: string;
  @Input() modify: 'lowerCase' | 'titleCase' | 'upperCase';
  // MASK
  @Input() controlMask: string;
  @Input('suffix') maskSuffix = '';
  @Input('prefix') maskPrefix = '';
  @Input('thousandSeparator') maskThousandSeparator = ' ';
  @Input('dropSpecialCharacters') maskDropSpecialCharacters = false;
  @Input('specialCharacters') maskSpecialCharacters = [];
  @Input() filter: boolean;
  @Input() showPasswordEyeIcon = false;

  uniqueId: string;
  disabled: boolean;

  private lowerCasePipe: LowerCasePipe;
  private titleCasePipe: TitleCasePipe;
  private upperCasePipe: UpperCasePipe;

  onTouched = () => {};
  onChanged = (_: any) => {};

  get controlContainer(): any {
    return this.injector.get(ControlContainer);
  }

  get control(): FormControl {
    return this.formControl || this.controlContainer.control.get(this.formControlName);
  }

  get element(): HTMLInputElement {
    return this.elementRef?.nativeElement;
  }

  get errors(): any {
    return (this.control && this.control.errors) || false;
  }

  constructor(public injector: Injector,
              public queryService: QueryService
  ) {
    this.uniqueId = utils.makeId();
    this.lowerCasePipe = injector.get(LowerCasePipe);
    this.titleCasePipe = injector.get(TitleCasePipe);
    this.upperCasePipe = injector.get(UpperCasePipe);
  }

  ngOnInit(): void {
    this.setFilterValueFromQuery();
  }

  setFilterValueFromQuery() {
    if (this.filter) {
      const queryValue = this.queryService.getValueFromQueryByName(this.formControlName);
      if (queryValue) {
        this.writeValue(queryValue);
      }
    }
  }

  writeValue(value: any, emitModelToViewChange = false): void {
    switch (this.modify) {
      case 'lowerCase':
        value = this.lowerCasePipe.transform(value);
        break;
      case 'titleCase':
        value = this.titleCasePipe.transform(value);
        break;
      case 'upperCase':
        value = this.upperCasePipe.transform(value);
        break;
    }
    this.control.patchValue(value, { emitEvent: false, emitModelToViewChange });
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  changeShowHideIcon() {
    this.type = this.type === 'text' ? 'password' : 'text';
  }
}
