import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { finalize } from 'rxjs/operators';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FormBaseModal } from '@shared/helpers/formBaseModal';
import { RateService } from '@shared/services/rate.service';
import {Router} from "@angular/router";

@UntilDestroy()
@Component({
  selector: 'app-modal-exchange-rate-create',
  templateUrl: './modal-exchange-rate-create.component.html',
  styleUrls: ['./modal-exchange-rate-create.component.scss']
})
export class ModalExchangeRateCreateComponent extends FormBaseModal<any> {

  date: Date;
  from: string;
  to: string;
  previousRate: string;

  constructor(public bsModalRef: BsModalRef,
              private _rateService: RateService,
              private _router: Router) {
    super();
  }

  onSubmit() {
    this.formData.controls.client_rate.setValue(this.formData.controls.internal_rate.value);
    if (this.formData.valid) {
      this.loading = true;
      this._rateService.create(this.formData.value)
        .pipe(
          untilDestroyed(this),
          finalize(() => this.loading = false)
        )
        .subscribe((response: any) => {
          this.onClose.next(response);
          this._router.navigate(['accounting/exchange-rates']);
          this.bsModalRef.hide();
        });
    } else {
      this.formData.markAllAsTouched();
    }
  }

  createForm() {
    this.formData = new FormGroup({
      original_currency: new FormControl('', [Validators.required]),
      target_currency: new FormControl('', [Validators.required]),
      internal_rate: new FormControl(this.previousRate ? this.previousRate : '', [Validators.required]),
      client_rate: new FormControl(''),
      created_at: new FormControl(this.date || null),
    });
  }

}
