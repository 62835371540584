import { Component, HostBinding, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { filter } from 'rxjs/operators';
import { CollapseDirective } from 'ngx-bootstrap/collapse';

import { NavigationService } from '@shared/layouts/navigation/navigation.service';
import { TokenService } from '@shared/services/token.service';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  items: any[];
  userData: any;

  @HostBinding('class.open') isOpened: boolean;
  @ViewChildren('collapse') collapse: QueryList<CollapseDirective>;

  constructor(private _router: Router,
              private _navigationService: NavigationService,
              public _tokenService: TokenService) { }

  ngOnInit() {
    this.items = this._navigationService.navigation();
    this.userData = this._tokenService.currentUserData;

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.collapseAll();
      });
  }

  logOut() {
    this._tokenService.logOut().unsubscribe();
  }

  collapseAll() {
    this.isOpened = false;
    this.collapse.forEach((item) => item.hide());
  }

}
