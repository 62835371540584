import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ToastrModule } from 'ngx-toastr';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarModule } from '@ngx-loading-bar/core';

import { AppRoutingModule } from './app-routing.module';
import { HttpService } from '@shared/services/token.intercepter';
import { DataService } from '@shared/services/data.interceptor';
import { AppComponent } from './app.component';
import { WrapperComponent } from '@shared/layouts/wrapper/wrapper.component';
import { NavigationComponent } from '@shared/layouts/navigation/navigation.component';
import { HeaderComponent } from '@shared/layouts/header/header.component';
import { WebsocketModule } from '@shared/websocket';
import { environment } from '../environments/environment';
import {TooltipModule} from "ngx-bootstrap/tooltip";
import {FormModule} from "@shared/modules/forms/form.module";
import {BsModalService} from "ngx-bootstrap/modal";

@NgModule({
  declarations: [
    AppComponent,
    WrapperComponent,
    NavigationComponent,
    HeaderComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    CollapseModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    ToastrModule.forRoot({
      progressBar: true
    }),
    WebsocketModule.config({
      url: environment.ws
    }),
    LoadingBarHttpClientModule,
    LoadingBarRouterModule,
    LoadingBarModule,
    TooltipModule,
    ReactiveFormsModule,
    FormModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HttpService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: DataService, multi: true },
    BsModalService
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
