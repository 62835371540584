import {Component, OnDestroy, OnInit} from '@angular/core';
import {RateService} from "@shared/services/rate.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BsModalRef} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {debounceTime} from "rxjs/operators";
import {ApiService} from "../../../pages/directory/services/api.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-modal-currency-exchange',
  templateUrl: './modal-currency-converter.component.html',
  styleUrls: ['./modal-currency-converter.component.scss']
})
export class ModalCurrencyConverterComponent implements OnInit, OnDestroy {
  formData: FormGroup = new FormGroup({
    currency_from: new FormControl('', [Validators.required]),
    currency_to: new FormControl('', [Validators.required]),
    initial_amount: new FormControl(null, [Validators.required, Validators.min(0)]),
    converted_amount: new FormControl({value: '', disabled: true}, [Validators.min(0)])
  });
  rates: any;
  currencies: any;
  private _subs: Subscription = new Subscription();

  constructor(private _apiService: ApiService,
              private _rateService: RateService,
              public bsModalRef: BsModalRef,
              private _toastr: ToastrService) {
  }

  ngOnInit(): void {
    this._subs.add(
      this._apiService.getCurrencies().subscribe(resp => {
        this.currencies = resp.data;
      })
    );
    this._subs.add(
      this._rateService.fullList()
        .subscribe(resp => {
          console.log('111 resp', resp);
          this.rates = resp;
          this.formData.controls.currency_from.setValue('USD', {emitEvent: false});
          this.formData.controls.currency_to.setValue('GBP', {emitEvent: false});
          this.formData.controls.initial_amount.setValue(1, {emitEvent: false});
          this.calculate();
          resp.forEach(rate => {
            if (rate.original_currency.named === 'GBP' && ['USD', 'EUR', 'AED', 'RUB'].includes(rate.target_currency.named)) {
              // this.ratesForm.controls[rate.target_currency.named].setValue(rate.client_rate);
            }
          });
        })
    );
    this.subscribeFormValuesChanges();
  }

  addShortCut(formName, value) {
    this.formData.controls[formName].setValue(value);
  }

  subscribeFormValuesChanges() {
    this.formData.controls.currency_from.valueChanges
      .pipe(debounceTime(500))
      .subscribe(val => {
        this.calculate();
      });
    this.formData.controls.currency_to.valueChanges
      .pipe(debounceTime(500))
      .subscribe(val => {
        this.calculate();
      });
    this.formData.controls.initial_amount.valueChanges
      .pipe(debounceTime(500))
      .subscribe(val => {
        this.calculate();
      });
  }

  calculate() {
    if (this.formData.value.currency_from && this.formData.value.currency_to && this.formData.value.initial_amount) {
      if (this.formData.controls.currency_from.value === this.formData.controls.currency_to.value) {
        this.formData.controls.converted_amount.setValue(this.formData.controls.initial_amount.value.toFixed(4));
      } else {
        const activeRate = this.rates.find(rate => rate.original_currency.named === this.formData.controls.currency_from.value && rate.target_currency.named === this.formData.controls.currency_to.value);
        if (activeRate?.client_rate) {
          const getSum = this.formData.controls.initial_amount.value * +activeRate.client_rate;
          this.formData.controls.converted_amount.setValue(getSum.toFixed(4));
        } else {
          this.formData.controls.converted_amount.setValue(0);
          this._toastr.error('Rate not found for this currencies');
        }

      }
    } else {
      this.formData.markAllAsTouched();
      this._toastr.error('Fill all required fields');
    }
  }

  switchValues() {
    const oldValue = this.formData.value.currency_from;
    this.formData.controls.currency_from.setValue(this.formData.controls.currency_to.value, {emitEvent: false});
    this.formData.controls.currency_to.setValue(oldValue);
  }
  ngOnDestroy() {
    this._subs.unsubscribe();
  }
}
